/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { AxiosResponse } from 'axios';
import { TableList, Container, Filter } from './styles';

import Table, { IColumn } from '~/components/Table';
import FiltersLists from '~/components/FiltersLists';
import ExportCsv from '~/components/ExportCsv';
import api from '~/services/api';
import { IOption } from '~/components/Select';

interface ITicketData {
  id: number;
  user_id: number;
  code: number;
  document: string;
  email: string;
  name: string;
  status: string;
  modality?: {
    id: number;
    name: string;
  };
  batchCategory: {
    id: number;
    batch: {
      event: {
        id: number;
        name: string;
        description: string;
        date: string;
        type: {
          name: string;
        };
        event: {
          date: string;
        };
        address: {
          street: string;
          number: number;
          neighborhood: string;
          city: string;
          state: string;
          complement: string;
          zip_code: string;
        };
      };
    };
    category: {
      name: string;
    };
  };
  orderItem: {
    order: {
      status: string;
      code: number;
      payment: {
        qr_code?: string;
        payment_type: string;
      };
      created_at: string;
      user: {
        name: string;
        email: string;
        additionalInformation: {
          phone: string;
        };
      };
    };
  };
  kitUser?: {
    color?: {
      name: string;
    };
    size?: {
      size: string;
    };
  };
  created_at: string;
}

interface ITicket {
  id: string;
  name: string;
  document: string;
  email: string;
  event_id: number;
  event_name: string;
  event_date: string;
  modality: string | undefined;
  category: string;
  kit: string;
}

const Users: React.FC = () => {
  const [pageSelected, setPageSelected] = useState(1);
  const [tableData] = useState({
    from: 0,
    to: 0,
    total: 0,
    totalPage: 0,
  });
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [statusSelected, setStatusSelected] = useState<string | undefined>();

  const status = useMemo(
    () => [
      {
        id: 'Disponível',
        value: 'Não Atribuido',
        selected: true,
      },
      {
        id: 'Usado',
        value: 'Atribuido',
        selected: false,
      },
    ],
    []
  );

  const handleLoadTickets = useCallback(async (page, statusData?: string) => {
    const response: AxiosResponse<ITicketData[]> = await api.get(
      `tickets/events/organizer`,
      {
        params: {
          // page,
          status: statusData ? [statusData] : [],
          allEventsStatus: true,
        },
      }
    );

    const dataTicket = response.data.map((ticket) => {
      return {
        id: `#${ticket.code}`,
        name: ticket.name || '-',
        document: ticket.document || '-',
        email: ticket.email || '-',
        event_id: ticket.batchCategory.batch.event.id,
        event_name: ticket.batchCategory.batch.event.name,
        event_date: format(
          parseISO(ticket.batchCategory.batch.event.date),
          'dd/MM/yyyy'
        ),
        modality: ticket.modality?.name,
        category: ticket.batchCategory.category.name,
        kit: ticket.kitUser
          ? `${
              ticket.kitUser.color ? `Cor: ${ticket.kitUser.color.name}, ` : ''
            }${
              ticket.kitUser.size ? `Tamanho: ${ticket.kitUser.size.size}` : ''
            }`
          : 'Não Possui',
        buy_date: format(parseISO(ticket.created_at), 'dd/MM/yyyy'),
        buyer_name: ticket.orderItem.order.user.name,
        buyer_phone: ticket.orderItem.order.user.additionalInformation.phone,
        buyer_email: ticket.orderItem.order.user.email,
      };
    });

    setTickets(dataTicket);
  }, []);

  useEffect(() => {
    handleLoadTickets(1);
  }, [handleLoadTickets]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Nome',
        selector: 'name',
      },
      {
        name: 'Documento',
        selector: 'document',
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Id do evento',
        selector: 'event_id',
      },
      {
        name: 'Nome do evento',
        selector: 'event_name',
      },
      {
        name: 'Data do evento',
        selector: 'event_date',
      },
      {
        name: 'Modalidade',
        selector: 'modality',
      },
      {
        name: 'Categoria',
        selector: 'category',
      },
      {
        name: 'Kit',
        selector: 'kit',
      },
      {
        name: 'Data compra',
        selector: 'buy_date',
      },
      {
        name: 'Nome comprador',
        selector: 'buyer_name',
      },
      {
        name: 'Fone comprador',
        selector: 'buyer_phone',
      },
      {
        name: 'email comprador',
        selector: 'buyer_email',
      },
    ],
    []
  );

  const handleChangePage = useCallback(
    (page) => {
      handleLoadTickets(page, statusSelected);
      setPageSelected(page);
    },
    [handleLoadTickets, statusSelected]
  );

  const handleSubmitFilters = useCallback(
    (formData) => {
      setStatusSelected(
        formData.statusTicket !== 'todos' ? formData.statusTicket : undefined
      );
      handleLoadTickets(
        1,
        formData.statusTicket !== 'todos' ? formData.statusTicket : undefined
      );
    },
    [handleLoadTickets]
  );

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="zoom col-12 px-0">
            <div className="bg-white d-sm-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Lista de ingressos</h1>

              <ExportCsv
                data={tickets}
                columns={columns}
                fileName="Lista_de_ingressos"
                // onClick={handleClickExportCsv}
              />
            </div>
          </div>
          <Filter className="col-12 px-0 mt-3">
            <FiltersLists
              onSubmitFilters={handleSubmitFilters}
              event
              statusTicket
              statusOptions={status}
            />
          </Filter>
          <div className="col-12 px-0">
            <div className="row">
              <TableList className="col-12 mt-3">
                <div className="bg-white pb-2">
                  <div className="bg-white overflow-auto">
                    <div className="">
                      <Table
                        className="mb-0 p-0 table"
                        columns={columns}
                        data={tickets}
                        pagination
                        totalData={tableData.total}
                        selectedPage={pageSelected}
                        // onChangePage={handleChangePage}
                      />
                    </div>
                  </div>
                </div>
              </TableList>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Users;
