import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import axios from 'axios';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import ReactQuill from 'react-quill';
import { FiPlus } from 'react-icons/fi';
import { set } from 'date-fns';
import Swal from 'sweetalert2';
import { Container } from './styles';
import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';
import InputMask from '~/components/InputMask';
import InputDate from '~/components/InputDate';
import Textarea from '~/components/Textarea';
import api from '~/services/api';
import { KrClose, KrTrash } from '~/components/KoroIcons';
import InputCheckbox from '~/components/InputCheckbox';
import Toast from '~/utils/toast';

interface IType {
  id: number;
  name: string;
}

interface IBasicData {
  onChangeEventName(value: string): void;
  onChangeType(option: IOption): void;
  className: string;
}

interface IAnswer {
  text: string;
}

interface IQuestion {
  id: number;
  question: string;
  type: 'unique-choice' | 'multiple-choice' | 'free-text';
  answers?: IAnswer[];
  isRequired?: boolean;
}

const BasicData: React.FC<IBasicData> = ({
  onChangeEventName,
  onChangeType,
  className,
}) => {
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [types, setTypes] = useState<IOption[]>([]);
  const [date, setDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [salesEndDate, setSalesEndDate] = useState<Date>(new Date());
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');
  const [description, setDescription] = useState('');
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [tempAnswers, setTempAnswers] = useState<Record<number, string>>({});

  const handleLoadTypes = useCallback(async (search = '') => {
    const response = await api.get<IType[]>('events-types', {
      params: { search, isOrganizer: true },
    });
    const data = response.data.map<IOption>((type) => ({
      id: type.id,
      value: type.name,
      selected: false,
    }));
    setTypes(data);
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // Tamanhos de cabeçalhos
      ['bold', 'italic', 'underline'], // Estilos de texto
      [{ list: 'ordered' }, { list: 'bullet' }], // Listas
      [{ align: [] }], // Alinhamento
    ],
  };

  useEffect(() => {
    handleLoadTypes();
  }, [handleLoadTypes]);

  const handleChangeType = useCallback(
    (option) => {
      onChangeType(option);
    },
    [onChangeType]
  );

  const handleChangeDate = useCallback((value) => {
    setDate(value);
  }, []);

  const handleChangeEndDate = useCallback((value) => {
    setEndDate(value);
  }, []);

  const handleChangeSalesEndDate = useCallback((value) => {
    setSalesEndDate(value);
  }, []);

  const handleDescription = useCallback((e) => {
    setDescription(e);
  }, []);

  const handleChangeZipCode = useCallback(async (e) => {
    const { value } = e.target;
    if (value.length === 9) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );
      setZipCode(response.data.cep);
      setState(response.data.uf);
      setCity(response.data.localidade);
      setAddress(response.data.logradouro);
      setNeighborhood(response.data.bairro);
    }
  }, []);

  const handleState = useCallback((e) => {
    setState(e.target.value);
  }, []);

  const handleCity = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  const handleAddress = useCallback((e) => {
    setAddress(e.target.value);
  }, []);

  const handleNeighborhood = useCallback((e) => {
    setNeighborhood(e.target.value);
  }, []);

  const handleNumber = useCallback((e) => {
    setNumber(e.target.value);
  }, []);

  const addressMap = useMemo(() => {
    return address
      ? `${address} n˚ ${number}, ${neighborhood} - ${city}/${state}, CEP: ${zipCode}`
      : 'Brazil';
  }, [address, city, neighborhood, number, state, zipCode]);

  const handleQuestionFreeText = useCallback(() => {
    setQuestions([
      ...questions,
      {
        id: questions.length + 1,
        question: '',
        type: 'free-text',
        isRequired: true,
      },
    ]);
  }, [questions]);

  const handleQuestionUniqueChoice = useCallback(() => {
    setQuestions([
      ...questions,
      {
        id: questions.length + 1,
        question: '',
        type: 'unique-choice',
        isRequired: true,
      },
    ]);
  }, [questions]);

  const handleQuestionMultipleChoice = useCallback(() => {
    setQuestions([
      ...questions,
      {
        id: questions.length + 1,
        question: '',
        type: 'multiple-choice',
        isRequired: true,
      },
    ]);
  }, [questions]);

  const handleTempAnswerChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, questionIndex: number) => {
      setTempAnswers({
        ...tempAnswers,
        [questionIndex]: e.target.value,
      });
    },
    [tempAnswers]
  );

  const handleAddAnswer = useCallback(
    (questionIndex: number) => {
      const tempAnswer = tempAnswers[questionIndex];

      if (!tempAnswer) return; // Evita adicionar respostas vazias

      setQuestions((prevQuestions) =>
        prevQuestions.map((question, index) => {
          if (index === questionIndex) {
            return {
              ...question,
              answers: [...(question.answers || []), { text: tempAnswer }],
            };
          }
          return question;
        })
      );

      setTempAnswers((prev) => ({ ...prev, [questionIndex]: '' }));
    },
    [tempAnswers]
  );

  const handleQuestionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, questionIndex: number) => {
      const updatedText = e.target.value;

      setQuestions((prevQuestions) =>
        prevQuestions.map((question, index) => {
          if (index === questionIndex) {
            return {
              ...question,
              question: updatedText,
            };
          }
          return question;
        })
      );
    },
    []
  );

  const handleDeleteQuestion = useCallback((questionIndex: number) => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#ff6900',
      title: `Excluir pergunta?`,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#ff6900',
      cancelButtonColor: '#B34A00',
      reverseButtons: true,
    }).then((e: any) => {
      if (e.isConfirmed) {
        setQuestions((prevQuestions) =>
          prevQuestions.filter((_, index) => index !== questionIndex)
        );
        Toast.fire({
          icon: 'success',
          iconColor: '#ff6900',
          title: 'Pergunta excluída!',
        });
      }
    });
  }, []);

  const handleDeleteAnswer = useCallback(
    (questionIndex: number, answerIndex: number) => {
      setQuestions((prevQuestions) =>
        prevQuestions.map((question, index) => {
          if (index === questionIndex) {
            return {
              ...question,
              answers: question.answers?.filter((_, i) => i !== answerIndex),
            };
          }
          return question;
        })
      );
    },
    []
  );

  const handleCheckChange = useCallback((e, questionIndex: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question, index) => {
        if (index === questionIndex) {
          return {
            ...question,
            isRequired: e[0].selected,
          };
        }
        return question;
      })
    );
  }, []);

  const onChange = useCallback(
    (sourceId: string, sourceIndex: number, targetIndex: number) => {
      const updatedQuestions = swap(questions, sourceIndex, targetIndex);
      setQuestions(updatedQuestions);
    },
    [questions]
  );

  return (
    <Container className={className}>
      <div className="col-12">
        <h2 className="h4 fw-semibold text-center mt-5">1- Dados básicos</h2>
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Nome do evento</h3>
        <Input
          name="name"
          type="text"
          onChange={(e) => onChangeEventName(e.target.value)}
          className="input"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Esporte</h3>
        <Select
          name="type"
          className="input"
          options={types}
          onChange={handleChangeType}
          placeholder="Selecione"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Data Inicio</h3>
        <InputDate
          className="py-1"
          name="date"
          selected={date}
          onChange={handleChangeDate}
          colorSvg="#5D5D5D"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Data Fim</h3>
        <InputDate
          className="py-1"
          name="end_date"
          selected={endDate}
          onChange={handleChangeEndDate}
          colorSvg="#5D5D5D"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Fim das Vendas</h3>
        <InputDate
          className="py-1"
          name="sales_end_date"
          selected={salesEndDate}
          onChange={handleChangeSalesEndDate}
          colorSvg="#5D5D5D"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Status</h3>
        <Select
          name="status"
          className="input"
          options={[
            {
              id: 1,
              value: 'Rascunho',
              selected: true,
            },
          ]}
          placeholder="Selecione"
          disabled
        />
      </div>
      <div className="col-lg-4 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">País</h3>
        <Input
          value="Brasil"
          name="country"
          type="text"
          className="pe-none input"
        />
      </div>
      <div className="col-lg-4 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">CEP</h3>
        <InputMask
          kind="custom"
          options={{ mask: '99999-999' }}
          name="zipcode"
          value={zipCode}
          onChange={handleChangeZipCode}
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Estado</h3>
        <Input
          name="state"
          onChange={handleState}
          value={state}
          type="text"
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Cidade</h3>
        <Input
          name="city"
          value={city}
          onChange={handleCity}
          type="text"
          className="input"
        />
      </div>
      <div className="col-lg-12 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Endereço</h3>
        <Input
          name="address"
          value={address}
          onChange={handleAddress}
          type="text"
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Bairro</h3>
        <Input
          name="neighborhood"
          type="text"
          value={neighborhood}
          onChange={handleNeighborhood}
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Número</h3>
        <Input
          name="number"
          value={number}
          onChange={handleNumber}
          className="input"
        />
      </div>
      <div className="col-12 px-lg-2 mt-3">
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=${
            process.env.REACT_APP_MAPS_KEY
          }&q=${addressMap}&zoom=${addressMap === 'Brazil' ? 2 : 15}`}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="map"
        />
      </div>
      <div className="col-12 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Descrição</h3>

        <ReactQuill
          className="textarea"
          value={description}
          onChange={handleDescription}
          theme="snow"
          modules={modules}
        />
        <Input
          type="hidden"
          name="description"
          className="d-none"
          value={description}
        />
      </div>
      <div className="col-12 px-lg-2 mt-3">
        <div className="bg-questions p-3">
          <h4>Perguntas para os atletas</h4>
          <p className="mb-4">
            Faça perguntas importantes e obrigatórias para seus atletas, te
            ajudando na organização do evento.
            <br />
            Caso não tenha uma pergunta, clique em próximo.
          </p>
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="bg-white p-3">
                <h6 className="mb-3">Adicionar pergunta:</h6>
                <button
                  type="button"
                  onClick={handleQuestionMultipleChoice}
                  className="btn-title border-0 bg-transparent d-flex align-items-center mb-2"
                >
                  <FiPlus size={16} color="#FF842F" className="me-2" /> Múltipla
                  escola
                </button>
                <button
                  type="button"
                  onClick={handleQuestionUniqueChoice}
                  className="btn-title border-0 bg-transparent d-flex align-items-center mb-2"
                >
                  <FiPlus size={16} color="#FF842F" className="me-2" /> Escolha
                  única
                </button>
                <button
                  type="button"
                  onClick={handleQuestionFreeText}
                  className="btn-title border-0 bg-transparent d-flex align-items-center"
                >
                  <FiPlus size={16} color="#FF842F" className="me-2" /> Resposta
                  por escrita
                </button>
              </div>
            </div>
            <div className="col-lg-6" id="bg-questions">
              <GridContextProvider onChange={onChange}>
                <GridDropZone
                  id="questions"
                  boxesPerRow={1}
                  rowHeight={300}
                  style={{
                    borderRadius: '8px',
                    minHeight: 600,
                  }}
                >
                  {questions.map((question, index) => (
                    <GridItem key={question.id} className="grab">
                      <div
                        ref={(el) => {
                          questionRefs.current[index] = el;
                        }}
                        className="bg-white border cursor-grab p-3 mb-3"
                      >
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h6 className="btn-title mb-0">
                            {question.type === 'free-text' &&
                              'Resposta por escrito'}
                            {question.type === 'multiple-choice' &&
                              'Múltipla escolha'}
                            {question.type === 'unique-choice' &&
                              'Escolha única'}
                          </h6>{' '}
                          <hr />
                          <button
                            type="button"
                            onClick={() => handleDeleteQuestion(index)}
                            className="border-0 bg-transparent"
                            title="Excluir pergunta"
                          >
                            <KrTrash size={16} color="#F22525" />
                          </button>
                        </div>
                        <h6>Sua pergunta:</h6>
                        <div className="mb-3">
                          <Input
                            type="text"
                            className="input"
                            placeholder="Digite aqui"
                            name="freeText"
                            onChange={(e) =>
                              handleQuestionChange(e, question.id)
                            }
                          />
                          {question.type === 'free-text' && (
                            <InputCheckbox
                              type="checkbox"
                              name={`required_${index}`}
                              className="checkbox"
                              options={[
                                {
                                  value: 'required',
                                  label: 'Resposta obrigatória',
                                  selected: question.isRequired,
                                },
                              ]}
                              onChange={(e) => handleCheckChange(e, index)}
                            />
                          )}
                        </div>
                        {question.answers?.map((answer, indexAnswer) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={indexAnswer}>
                            <div className="answers d-flex align-items-center justify-content-between rounded-pill mb-2 px-3 py-2">
                              {answer.text}
                              <button
                                title="Excluir resposta"
                                type="button"
                                onClick={() =>
                                  handleDeleteAnswer(index, indexAnswer)
                                }
                                className="border-0 bg-transparent ms-2"
                              >
                                <KrClose size={12} color="#F22525" />
                              </button>
                            </div>
                          </div>
                        ))}

                        {question.type !== 'free-text' && (
                          <div className="mt-3">
                            <h6>Respostas:</h6>
                            <Input
                              type="text"
                              className="input"
                              placeholder="Digite aqui"
                              value={tempAnswers[index] || ''}
                              onChange={(e) => handleTempAnswerChange(e, index)}
                              name={
                                question.type === 'unique-choice'
                                  ? 'uniqueChoise'
                                  : 'multipleChoise'
                              }
                            />

                            <button
                              type="button"
                              onClick={() => handleAddAnswer(index)}
                              className="btn-add-aswer w-100 border-0 rounded-pill py-2 mt-2"
                            >
                              <FiPlus
                                size={17}
                                color="#777777"
                                className="me-2"
                              />{' '}
                              Adicionar
                            </button>
                          </div>
                        )}
                      </div>
                    </GridItem>
                  ))}
                </GridDropZone>
              </GridContextProvider>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BasicData;
