/* eslint-disable import/no-duplicates */
/* eslint-disable no-console */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { Form } from '@unform/web';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import Toast from '~/utils/toast';

import { Balance, Cashflow, Container, Extract, Filter, Modal } from './styles';
import Table, { IColumn } from '~/components/Table';
import FiltersLists from '~/components/FiltersLists';
import { IOption } from '~/components/Select';
import {
  KrClose,
  KrEye,
  KrEyeSlash,
  KrInfo,
  KrSavings,
} from '~/components/KoroIcons';
import InputMask from '~/components/InputMask';

import ticketSold from '~/assets/icons/ticket-sold-new.svg';

interface IStatus {
  id: number;
  name: string;
}

interface IEventType {
  id: number;
  name: string;
}

interface IExtractResponse {
  id: number;
  total: string;
  value: string;
  balance: string;
  retained: string;
  type: string;
  created_at: string;
  withdrawal?: {
    archive?: {
      archive_url: string;
    };
  };
}

interface IExtract {
  number: string;
  date: string;
  hour: string;
  action: string;
  value: string;
  balance: string;
  total: string;
  receipt?: string;
}

interface ICashFlowResponse {
  month: number;
  year: number;
  accumulated: number;
  received: number;
  drawn: number;
  receivables: number;
  balance: number;
}

interface ICashFlow {
  date: string;
  accumulated: string;
  received: string;
  drawn: string;
  receivables: string;
  balance: string;
}

const Financial: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [available, setAvailable] = useState(0);
  const [, setTotal] = useState(0);
  const [retained, setRetained] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState<IOption[]>([]);
  const [status, setStatus] = useState<IOption[]>([]);
  const [showValues, setShowValues] = useState(true);
  const [cashesFlow, setCashesFlow] = useState<ICashFlow[]>([]);
  const [extracts, setExtracts] = useState<IExtract[]>([]);
  const [withdraw, setWithdraw] = useState<number>(0);
  const [withdrawError, setWithdrawError] = useState('');

  useEffect(() => {
    api.get<IStatus[]>('events-status').then((response) => {
      const data = response.data.map<IOption>((statusData) => ({
        id: statusData.id,
        value: statusData.name,
        selected: false,
      }));
      setStatus(data);
    });
  }, []);

  useEffect(() => {
    api
      .get<IEventType[]>('events-types', { params: { isOrganizer: true } })
      .then((response) => {
        const data = response.data.map<IOption>((modality) => ({
          id: modality.id,
          value: modality.name,
          selected: false,
        }));

        setCategories(data);
      });
  }, []);

  useEffect(() => {
    api.get('/wallets/organizers').then((response) => {
      setAvailable(parseFloat(response.data.available));
      setTotal(parseFloat(response.data.total));
      setRetained(parseFloat(response.data.retained));
    });
  }, []);

  useEffect(() => {
    api.get<ICashFlowResponse[]>('cash-flows/organizer').then((response) => {
      const cashFlow = response.data
        .reverse()
        .map<ICashFlow>((cashFlowData) => {
          const date = format(
            new Date(cashFlowData.year, cashFlowData.month - 1),
            'MMM/yyyy',
            {
              locale: ptBR,
            }
          );
          return {
            date: `${date.slice(0, 1).toUpperCase()}${date.slice(1, 8)}`,
            accumulated: formatPrice(cashFlowData.accumulated),
            received: formatPrice(cashFlowData.received),
            drawn: formatPrice(cashFlowData.drawn),
            receivables: formatPrice(cashFlowData.receivables),
            balance: formatPrice(cashFlowData.balance),
          };
        });

      setCashesFlow(cashFlow);
    });
  }, []);

  const handleLoadExtracts = useCallback(
    async (
      event?: string,
      status_id?: string,
      type_id?: string,
      dateStart?: string,
      dateEnd?: string
    ) => {
      const response = await api.get<IExtractResponse[]>(
        '/extracts/organizers',
        {
          params: {
            event,
            status_id,
            type_id,
            dateStart: dateStart ? new Date(dateStart) : undefined,
            dateEnd: dateEnd ? new Date(dateEnd) : undefined,
          },
        }
      );
      //
      const data = response.data.reverse().map<IExtract>((extract) => ({
        number: extract.id.toString().padStart(6, '0'),
        date: format(parseISO(extract.created_at), 'dd/MM'),
        hour: format(parseISO(extract.created_at), 'HH:mm'),
        action: extract.type,
        value: formatPrice(parseFloat(extract.value)),
        total: formatPrice(parseFloat(extract.retained)),
        balance: formatPrice(parseFloat(extract.balance)),
        receipt: extract.withdrawal?.archive?.archive_url,
      }));

      setExtracts(data.reverse());
    },
    []
  );

  useEffect(() => {
    handleLoadExtracts();
  }, [handleLoadExtracts]);

  const columns = useMemo(
    () =>
      [
        {
          name: '',
          selector: 'date',
          sortable: false,
        },
        {
          name: 'Acumulado',
          selector: 'accumulated',
          sortable: false,
          toolTip:
            'Este é o valor total das vendas de ingressos em reais, somando todas as vendas realizadas até o momento.',
        },
        {
          name: 'Recebido',
          selector: 'received',
          sortable: false,
          toolTip:
            'Esse valor representa o total que já foi liberado para sua conta na KORO e está disponível para saque.',
        },
        {
          name: 'Sacado',
          selector: 'drawn',
          sortable: false,
          toolTip:
            'Aqui está o valor total em reais que você já retirou da sua conta na KORO através de saques.',
        },
        {
          name: 'Disponível',
          selector: 'balance',
          sortable: false,
          toolTip:
            'Este é o valor que já foi recebido na sua conta, mas que ainda não foi sacado. É calculado como o total Recebido menos o total Sacado.',
        },
        {
          name: 'A Receber',
          selector: 'receivables',
          sortable: false,
          toolTip:
            'Este é o valor que ainda falta ser recebido na sua conta. É a diferença entre o total Acumulado e o total Recebido.',
        },
      ] as IColumn[],
    []
  );

  const handleSubmitFilters = useCallback(
    (formData) => {
      handleLoadExtracts(
        formData.event,
        formData.status !== 'todos' ? formData.status : undefined,
        formData.sports !== 'todos' ? formData.sports : undefined,
        formData.dateStart,
        formData.dateEnd
      );
    },
    [handleLoadExtracts]
  );

  const handleWithdraw = useCallback((e) => {
    setWithdraw(
      parseFloat(
        e.target.value.replace('R$', '').replaceAll('.', '').replace(',', '.')
      )
    );
  }, []);

  useEffect(() => {
    if (withdraw !== undefined && withdraw > available) {
      setWithdrawError('Saldo insuficiente');
    } else {
      setWithdrawError('');
    }
  }, [available, withdraw]);

  const handleSubmitWithdraw = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          value: Yup.string().required('O valor é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('withdrawals/organizers', {
          value: withdraw,
        });

        const newCashesFlow = cashesFlow.slice();

        const drawn =
          parseFloat(
            newCashesFlow[0].drawn
              .replace('R$', '')
              .replaceAll('.', '')
              .replace(',', '.')
          ) + withdraw;
        const receivables =
          parseFloat(
            newCashesFlow[0].receivables
              .replace('R$', '')
              .replaceAll('.', '')
              .replace(',', '.')
          ) - withdraw;

        newCashesFlow[0].drawn = formatPrice(drawn);
        newCashesFlow[0].receivables = formatPrice(receivables);

        setCashesFlow(newCashesFlow);
        setExtracts((oldState) => [
          {
            number: response.data.id.toString().padStart(6, '0'),
            date: format(new Date(), 'dd/MM'),
            hour: format(new Date(), 'HH:mm'),
            action: 'Solicitação de saque',
            value: formatPrice(parseFloat(data.value)),
            total: oldState[0].total,
            balance: formatPrice(available - parseFloat(data.value)),
          },
          ...oldState,
        ]);

        console.log(available);
        console.log(withdraw);
        console.log(retained);

        setAvailable(available - withdraw);
        setRetained(retained + withdraw);

        Toast.fire({
          icon: 'success',
          iconColor: '#ff6900',
          title: 'Solicitação de saque recebida com sucesso!',
        });

        setShowModal(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Credenciais inválidas!');
        }
      }
    },
    [available, cashesFlow, retained, withdraw]
  );

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="zoom col-12 px-0">
            <div className="bg-white d-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Financeiro</h1>
            </div>
          </div>

          <div className="col-12 ps-lg-0">
            <div className="row">
              <Balance className="zoom col-12 pe-0">
                <div className="bg-balance p-3 mt-3">
                  <div className="d-flex align-items-center">
                    <div>
                      <span className="h6 text-white fw-normal d-block">
                        Disponível para saque
                      </span>
                      <span className="poppins d-block mb-0 min-w h2 text-white fw-bold">
                        {showValues ? formatPrice(available) : 'R$ -'}
                      </span>
                    </div>
                    <div className="d-flex">
                      <button
                        type="button"
                        onClick={() => setShowValues(!showValues)}
                        className="border-0 bg-transparent mt-4 ms-2"
                      >
                        {showValues ? (
                          <KrEye size={24} color="#FFBB85" />
                        ) : (
                          <KrEyeSlash size={24} color="#FFBB85" />
                        )}
                      </button>
                    </div>
                    {/* <div className="ms-4 ps-2">
                      <span className="h6 text-white fw-normal d-block">
                        Retido
                      </span>
                      <span className="poppins d-block mb-0 h4 text-white">
                        {showValues ? formatPrice(retained) : 'R$ -'}
                      </span>
                    </div> */}
                    <div className="ms-auto">
                      <button
                        type="button"
                        onClick={() => setShowModal(true)}
                        className="btn-withdraw border-0"
                      >
                        <KrSavings size={16} color="#fff" /> Sacar
                      </button>
                    </div>
                  </div>
                </div>
              </Balance>
              <Cashflow className="col-12 pe-0 mt-3">
                <div className="bg-white p-3 position-relative">
                  <h2 className="zoom h3 fw-semibold mb-0">
                    {/* <MdAccountBalance
                      size={24}
                      color="#FF6900"
                      className="me-2"
                    /> */}
                    Fluxo de caixa
                  </h2>

                  <hr />

                  <div>
                    <Table
                      className={`${
                        !showValues ? 'd-none' : ''
                      } mb-0 p-0 table`}
                      columns={columns}
                      data={cashesFlow}
                    />
                  </div>
                </div>
              </Cashflow>

              <Extract className="col-12 pe-0 mt-3">
                <div className="bg-white p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="h3 fw-semibold mb-0">Extrato</h2>
                    <Filter className="px-0">
                      <FiltersLists
                        onSubmitFilters={handleSubmitFilters}
                        date
                        categories={categories}
                        statusOptions={status}
                      />
                    </Filter>
                  </div>
                  <hr />
                  <div className={`${!showValues ? 'd-none' : ''}`}>
                    {extracts.map((item) => (
                      <>
                        <div className="bd d-flex px-3 py-2 my-2">
                          <div className="w-20">
                            <h3 className="h5 fw-semibold mb-0">
                              #{item.number}
                            </h3>
                            <p className="h6 fw-normal mb-0">
                              {item.date} - {item.hour}
                            </p>
                          </div>
                          <div className="w-20">
                            <span className="h6 fw-semibold mb-1 d-block">
                              Ação
                            </span>
                            <span className="green h6 fw-normal d-block mb-0">
                              {item.action}
                            </span>
                          </div>
                          <div className="w-20">
                            <span className="h6 fw-semibold mb-1 d-block">
                              Valor
                            </span>
                            <span className="gray h6 fw-normal d-block mb-0">
                              {item.value}
                            </span>
                          </div>
                          <div className="w-20 position-relative">
                            <span className="h6 fw-semibold mb-1 d-block">
                              Saldo total
                              <span
                                className="tooltip-total"
                                data-tooltip="Saldo total: tudo que foi vendido menos o que já foi transferido"
                              >
                                <KrInfo
                                  size={12}
                                  color="#202020"
                                  className="ms-1 mb-1"
                                />
                              </span>
                            </span>
                            <span className="blue h6 fw-normal d-block mb-0">
                              {item.total}
                            </span>
                          </div>
                          <div className="w-20 d-flex align-items-center">
                            <div className="position-relative">
                              <span className="h6 fw-semibold mb-1 d-block">
                                Saldo disponivel
                                <span
                                  className="tooltip-total"
                                  data-tooltip="Saldo dísponivel: valor que pode ser solicitado para transferência"
                                >
                                  <KrInfo
                                    size={12}
                                    color="#202020"
                                    className="ms-1 mb-1"
                                  />
                                </span>
                              </span>
                              <span className="blue h6 fw-normal d-block mb-0">
                                {item.balance}
                              </span>
                            </div>
                            {item.action === 'Transferência realizada' &&
                              item.receipt && (
                                <a
                                  href={item.receipt}
                                  className="btn-ticket border-0 rounded-circle ms-auto"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img src={ticketSold} alt="Comprovante" />
                                </a>
                              )}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </Extract>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="zoom modal-filter"
        size="lg"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Form onSubmit={handleSubmitWithdraw}>
          <Modal.Header className="justify-content-between align-items-center border-0 px-3 px-sm-5 pb-0 pt-0">
            <Modal.Title className="mb-0 mt-4">
              <h2 className="title mb-0">Solicitar saque</h2>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="border-0 bg-transparent"
            >
              <KrClose size={20} color="#989898" />
            </button>
          </Modal.Header>
          <Modal.Body className="container pt-0 px-3 px-sm-5">
            <div className="row">
              <div className="col-12">
                <hr className="mb-5" />
                <h3 className="h5 fw-normal mb-4">
                  Disponível para saque: {formatPrice(available)}
                </h3>
                <h4 className="h6 fw-normal">Quanto quer sacar?</h4>
                <div className="d-flex align-items-center">
                  <InputMask
                    placeholder="R$ 0,00"
                    name="value"
                    className="input height"
                    kind="money"
                    value={
                      withdraw ? withdraw?.toFixed(2).toString() : undefined
                    }
                    onChange={handleWithdraw}
                  />

                  <button
                    type="button"
                    onClick={() =>
                      setWithdraw(parseFloat(available.toString()))
                    }
                    className="h5 ms-0 btn-filter d-flex align-items-center justify-content-center btn fw-normal px-4 ms-4 mb-0"
                  >
                    Tudo
                  </button>
                </div>
                <p className="text-danger text-center mt-1">{withdrawError}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 mt-5 py-4 px-5 d-flex justify-content-end">
            <button
              type="submit"
              disabled={!!withdrawError}
              className={`${
                withdrawError ? 'opacity-50' : 'opacity-100'
              } mx-0 btn-filter d-flex align-items-center justify-content-center btn h6 fw-normal py-2 px-4 mt-2 mb-0`}
            >
              Solicitar saque
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Financial;
